
import { computed, defineComponent, PropType, ref } from 'vue'
import i18n from '@web/plugins/i18n'
import { useStore } from '@web/store'
import Logger from '@web/common/Logger'
import { useRouter } from 'vue-router'

import AcModal from '@ui-kit/components/AcModal.vue'
import AcTextarea from '@ui-kit/components/AcTextarea.vue'
import AcButton from '@ui-kit/components/AcButton.vue'
import { Subscription } from '@web/types/Subscription'

export default defineComponent({
  name: 'ModalCancelSubscription',
  props: {
    subscription: {
      type: Object as PropType<Subscription>,
      required: true
    }
  },
  components: {
    AcModal,
    AcTextarea,
    AcButton
  },
  emits: ['close'],
  setup (props, ctx) {
    const store = useStore()
    const router = useRouter()

    enum statuses {
      ready = 'ready',
      wait = 'wait',
      fail = 'fail',
      success = 'success'
    }

    const status = ref<statuses>(statuses.ready)
    const reasonText = ref('')
    const cancelPopupText = computed(() => props.subscription.cancelPopupText || i18n.global.t('cancelSubscribeBtn.defaultText'))
    const readyBtnText = computed(() => {
      if (status.value === statuses.wait) {
        return i18n.global.t('cancelSubscribeBtn.wait')
      }

      return i18n.global.t('Unsubscribe')
    })
    const isReadyBtnDisabled = computed(() => {
      return status.value === statuses.wait
    })
    const text = computed(() => i18n.global.t(`cancelSubscribeBtn.${status.value}`))

    async function cancelSubscription () {
      if (isReadyBtnDisabled.value) {
        return
      }

      try {
        status.value = statuses.wait
        const response = await store.dispatch('subscription/cancelSubscription', {
          id: props.subscription.id,
          reasonType: i18n.global.t('cancelSubscribeBtn.other'),
          reason: reasonText.value
        })

        if (response.redirectUrl) {
          window.open(response.redirectUrl, '_system')
        }
        await store.dispatch('subscription/fetchSubscriptions')
        status.value = statuses.success
        ctx.emit('close')
      } catch (e) {
        status.value = statuses.fail
        Logger.error(e)
      }
    }

    function closeModalSubscription () {
      reasonText.value = ''
      ctx.emit('close')
    }

    function contactToSupport () {
      router.push({ name: 'support' })
    }

    return {
      text,
      status,
      reasonText,
      cancelPopupText,
      readyBtnText,
      isReadyBtnDisabled,
      cancelSubscription,
      closeModalSubscription,
      contactToSupport
    }
  }
})
