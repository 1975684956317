import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3367263c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content_row" }
const _hoisted_2 = { class: "content_title" }
const _hoisted_3 = {
  key: 0,
  class: "content_column"
}
const _hoisted_4 = {
  key: 0,
  class: "content_courses access"
}
const _hoisted_5 = { class: "access_title" }
const _hoisted_6 = {
  key: 1,
  class: "content_clubs access"
}
const _hoisted_7 = { class: "access_title" }
const _hoisted_8 = {
  key: 1,
  class: "content_row -reverse"
}
const _hoisted_9 = { class: "content_endDate" }
const _hoisted_10 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal_cancel_subscription = _resolveComponent("modal-cancel-subscription")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['subscription', `-${_ctx.status}`])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(['subscription_content', 'content', `${_ctx.subscription.isLifetime ? '-lifetime' : ''}`])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.subscription.title), 1)
      ]),
      (_ctx.accessListCourse || _ctx.accessListClub)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_ctx.accessListCourse)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createElementVNode("i", _hoisted_5, _toDisplayString(_ctx.$t('subscription.accessCourse')) + ": ", 1),
                  _createTextVNode(" " + _toDisplayString(_ctx.accessListCourse), 1)
                ]))
              : _createCommentVNode("", true),
            (_ctx.accessListClub)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("i", _hoisted_7, _toDisplayString(_ctx.$t('subscription.accessClub')) + ": ", 1),
                  _createTextVNode(" " + _toDisplayString(_ctx.accessListClub), 1)
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (_ctx.subscription.recurrentPaymentsEnabled)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.subscription.endDate), 1),
            _createElementVNode("div", {
              class: "content_amount",
              innerHTML: _ctx.amountText
            }, null, 8, _hoisted_10)
          ]))
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.showActionCancel)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "subscription_footer -active",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.cancelSubcription && _ctx.cancelSubcription(...args)))
        }, _toDisplayString(_ctx.$t('cancelSubscribeBtn.ready')), 1))
      : _createCommentVNode("", true),
    (_ctx.showActionRenew)
      ? (_openBlock(), _createElementBlock("button", {
          key: 1,
          class: "subscription_footer",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.renewSubcription && _ctx.renewSubcription(...args)))
        }, _toDisplayString(_ctx.$t('cancelSubscribeBtn.cancel')), 1))
      : _createCommentVNode("", true),
    (_ctx.showModalCancelSubscription)
      ? (_openBlock(), _createBlock(_component_modal_cancel_subscription, {
          key: 2,
          subscription: _ctx.subscription,
          onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showModalCancelSubscription = false))
        }, null, 8, ["subscription"]))
      : _createCommentVNode("", true)
  ], 2))
}